import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"



const IndexPage = () => {
    const root = "/img/download/business-lunch"

    return (
        <Layout pageTitle="Business Lunch - Templates">
            <Row>
                <Col>
                    <Link to="/events/business-lunch">Back</Link>
                </Col>
            </Row>
                
            <ImageDownload
            root ="/img/download/business-lunch"
            filename="lunch-speaker"
            name="Business Lunch Speaker Promo Template"
            text="Use this template to create speaker promos for your business lunch events"
            text2=""
            squareHref="https://www.canva.com/design/DAE1zsEU7qc/ZR-7R1w73O357MnBTUv1Dg/view?utm_content=DAE1zsEU7qc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAE10GA-u8o/_T-O6a_SBkPUTLggZn4Vwg/view?utm_content=DAE10GA-u8o&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1GSqSN7N2IH3-a1uskMVHJ58BwwQQC3UQeiPVwxlHnSw/edit?usp=sharing"
            />  
           
           <ImageDownload
            root ="/img/download/business-lunch"
            filename="lunch-topic-promo"
            name="Business Lunch Event Promo Template"
            text="Use this template to create event promos for your business lunch events"
            text2=""
            squareHref="https://www.canva.com/design/DAFpLZ5AFtw/Xu_aLPje1_F3ViGBphx1vQ/view?utm_content=DAFpLZ5AFtw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFpLSkJMYs/eEBgDiagh9jsIXo2gIDwGw/view?utm_content=DAFpLSkJMYs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1GSqSN7N2IH3-a1uskMVHJ58BwwQQC3UQeiPVwxlHnSw/edit?usp=sharing"
            />  

            
        </Layout>

        
    )
}

export default IndexPage
